//
// general-rtl.scss
//

html {
  direction: rtl;
}

body {
  text-align: right;
}

.card-preview {
  left: 0px;
  right: unset;
}
